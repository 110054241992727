console.group("inicializando o pack css do app")

import './application.scss'
import './overrides'
import './recyclable'
import './components/institutional'
import './components/main'
import './components/providers'
import './components/products'
import './components/security'
import './components/feedback'
import './components/footer'


console.groupEnd();
